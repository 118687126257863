import QuerystringHelper from "@/helpers/querystring-helper";
import {
  PagedList,
  Student,
  StudentParameters,
  OverviewStudent,
  PersonResponse,
} from "@/interfaces/api";
import BaseService from "@/services/base-service";

class StudentService extends BaseService {
  /**
   * Fetches the student with the specified id
   *
   * @param {number} id - The id of the student to fetch
   * @returns {Promise<PersonResponse>} A Promise with a person response
   */
  getStudentById(id: number): Promise<PersonResponse> {
    const endpointController = "Student";
    const enpointRoute = "id";
    const parameterName = "id";
    const parameterArgument = id;
    const getStudentById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${parameterName}=${parameterArgument}`;
    return this.fetch<PersonResponse>(getStudentById);
  }

  /* Fetches students according to the specified filter/paging parameters
   *
   * @param {StudentParameters} studentParameters - The filter/paging parameters to be added to the resource querystring
   * @returns {Promise<PagedList<Student>>} A Promise with all the students that matched the parameters
   */
  getFiltered(
    studentParameters: StudentParameters
  ): Promise<PagedList<OverviewStudent>> {
    const endpointController = "Student";
    const enpointRoute = "search";
    const queryString = this.generateQuerystring(studentParameters);
    const getStudentById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}${queryString}`;
    return this.fetch<PagedList<OverviewStudent>>(getStudentById);
  }

  /**
   * Returns a query string according to the specified filter parameters
   *
   * @param {StudentParameters} studentParameters - The filter/paging parameters to be added to the resource querystring
   * @returns {string} The generated query string
   */
  private generateQuerystring(studentParameters: StudentParameters): string {
    let queryString = "?";

    queryString += `CoordinatorId=${studentParameters.coordinatorId}&`;

    queryString +=
      QuerystringHelper.generateSearchAndPagingParameters(studentParameters);

    return queryString;
  }

  /**
   * Fetches a student response containing the student with the specified personal id and also a
   * boolean value representing whether or not the student already exists in the STP database
   *
   * @param {string} personalId - The personal id of the student to fetch
   * @returns {Promise<PersonResponse>} A Promise with a person response
   */
  getStudentResponseByPersonalId(personalId: string): Promise<PersonResponse> {
    const endpointController = "Student";
    const endpointRoute = "personalId";
    const parameterName = "personalId";
    const parameterArgument = personalId;
    const queryString = `${parameterName}=${parameterArgument}`;
    const getStudentResponseByPersonalId = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<PersonResponse>(getStudentResponseByPersonalId);
  }

  /**
   * Adds or updates a student
   *
   * @param {Student} student - The student to add och update
   * @returns {Promise<Student>} A promise with a boolean value indicating whether or not the operation was successful
   */
  addOrUpdateStudent(student: Student): Promise<Student> {
    const endpointController = "Student";
    const addOrUpdateStudent = `${this.baseApiUrl}api/${endpointController}`;
    return this.fetch<Student>(addOrUpdateStudent, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    });
  }
}

export default new StudentService();
