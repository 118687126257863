import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cf7bb3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "form-container" }
const _hoisted_4 = { class: "text-input-container" }
const _hoisted_5 = ["disabled", "aria-label"]
const _hoisted_6 = {
  key: 0,
  class: "student-container"
}
const _hoisted_7 = {
  key: 0,
  class: "unsuccessful"
}
const _hoisted_8 = {
  key: 1,
  class: "save-successful"
}
const _hoisted_9 = { class: "student-container" }
const _hoisted_10 = {
  key: 2,
  class: "unsuccessful"
}
const _hoisted_11 = {
  key: 3,
  class: "unsuccessful"
}
const _hoisted_12 = {
  key: 4,
  class: "unsuccessful"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "note-taker-container" }
const _hoisted_15 = { key: 5 }
const _hoisted_16 = { class: "info-container" }
const _hoisted_17 = {
  key: 0,
  class: "later-start-date-container"
}
const _hoisted_18 = { class: "later-start-date" }
const _hoisted_19 = { class: "button-container" }
const _hoisted_20 = ["aria-label"]
const _hoisted_21 = ["aria-label", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailsHeader = _resolveComponent("DetailsHeader")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PersonDetails = _resolveComponent("PersonDetails")!
  const _component_MandatoryFieldFlag = _resolveComponent("MandatoryFieldFlag")!
  const _component_CourseInput = _resolveComponent("CourseInput")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DetailsHeader, {
      title: _ctx.$t('shared.routes.add-student'),
      showPrevious: false,
      showNext: false,
      onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToOverview()))
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_TextInput, {
            class: "mr-2 personal-id-input",
            ref: "personalIdInput",
            label: _ctx.$t('person.personalId'),
            required: true,
            placeholder: _ctx.placeholder,
            maxLength: _ctx.maxLength,
            pattern: _ctx.pattern,
            value: this.personalId,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((this.personalId) = $event)),
            onKeyup: _withKeys(_ctx.fetchStudent, ["enter"])
          }, null, 8, ["label", "placeholder", "maxLength", "pattern", "value", "onKeyup"]),
          _createElementVNode("button", {
            id: "fetchBtn",
            class: "btn btn-outline-primary",
            disabled: _ctx.fetchButtonIsDisabled,
            "aria-label": _ctx.$t('shared.search'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchStudent()))
          }, _toDisplayString(_ctx.$t("shared.search")), 9, _hoisted_5)
        ]),
        (_ctx.personResponse)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.personResponse.studentExists)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t("add-student.studentExists")) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: {
                name: 'StudentDetails',
                params: { id: _ctx.personResponse.student.id },
              }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.personResponse.student.personalId), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.saveWasSuccessful)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.showNameMsg("add-student.saveSuccessful")) + " ", 1),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_router_link, {
                        to: {
                  name: 'StudentDetails',
                  params: { id: _ctx.addedStudent.id },
                }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.personResponse.student.personalId), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.saveWasSuccessful === false)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("add-student.saveUnsuccessful")), 1))
                : _createCommentVNode("", true),
              (!_ctx.personResponse.student)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("add-student.studentDoesNotExist")), 1))
                : _createCommentVNode("", true),
              (_ctx.personResponse.isActiveNoteTaker)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.showNameMsg('add-student.activeNoteTaker')
                    }, null, 8, _hoisted_13),
                    _createElementVNode("span", _hoisted_14, [
                      _createVNode(_component_router_link, {
                        to: {
                  name: 'NoteTakerDetails',
                  params: { id: _ctx.personResponse.noteTakerId },
                }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("shared.routes.note-taker-details")), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showStudentDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_PersonDetails, {
                        person: _ctx.personResponse.student,
                        showCourses: false,
                        showCoordinator: true,
                        showDatePicker: false,
                        editCoordinator: true,
                        functionId: _ctx.functionId,
                        requiredCoordinator: true
                      }, null, 8, ["person", "functionId"]),
                      (_ctx.hasLaterStartDates)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.laterStartDateText), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t("shared.courses")), 1),
                          _createVNode(_component_MandatoryFieldFlag)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.terms, (term) => {
                          return (_openBlock(), _createBlock(_component_Accordion, {
                            key: term,
                            class: "mb-3",
                            expanded: true
                          }, {
                            button: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTermString(term)), 1)
                            ]),
                            content: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCoursesByTerm(term), (course) => {
                                return (_openBlock(), _createBlock(_component_CourseInput, {
                                  key: course.courseCode,
                                  course: course,
                                  isHighlighted: 
                        _ctx.isCourseWithLaterStartDate(course.courseUid)
                      ,
                                  isCompleted: _ctx.isCompletedCourse(course.courseUid),
                                  onCourseCheckedChanged: _ctx.onCourseCheckedChanged,
                                  class: _normalizeClass(
                        _ctx.isCourseWithLaterStartDate(course.courseUid)
                          ? 'highlighted-course'
                          : ''
                      )
                                }, null, 8, ["course", "isHighlighted", "isCompleted", "onCourseCheckedChanged", "class"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024))
                        }), 128)),
                        _createVNode(_component_DatePicker, {
                          value: _ctx.personResponse.student.grantedSupportDate,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.personResponse.student.grantedSupportDate) = $event)),
                          label: _ctx.$t('add-student.grantedSupportUntil'),
                          required: true,
                          clearable: true,
                          disabledDates: _ctx.disabledDates
                        }, null, 8, ["value", "label", "disabledDates"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("button", {
                        id: "cancelBtn",
                        class: "btn btn-outline-primary mr-2",
                        "aria-label": _ctx.$t('shared.cancel'),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCancelClicked()))
                      }, _toDisplayString(_ctx.$t("shared.cancel")), 9, _hoisted_20),
                      _createElementVNode("button", {
                        id: "saveBtn",
                        class: "btn btn-outline-primary",
                        "aria-label": _ctx.$t('shared.save'),
                        disabled: _ctx.saveButtonIsDisabled,
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSaveClicked()))
                      }, _toDisplayString(_ctx.$t("shared.save")), 9, _hoisted_21)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}